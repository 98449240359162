import currency from 'currency.js';
import { FetchStockMarketLeagueUsersQuery } from '../../../../../generated/hooks';

const BUY_IN = 20;

export type PlayerStandingsData = {
  players: {
    id: string;
    fullName: string;
    cashBalance: currency;
    holdings: currency;
    totalWorth: currency;
    winnings: currency;
  }[];
  totals: {
    cashBalance: currency;
    holdings: currency;
    totalWorth: currency;
  };
  averages: {
    cashBalance: currency;
    holdings: currency;
    totalWorth: currency;
  };
};

export default function getPlayerStandingsData(
  users: FetchStockMarketLeagueUsersQuery['league']['users'],
): PlayerStandingsData {
  if (!users) {
    return null;
  }

  const data: PlayerStandingsData = {
    players: [],
    totals: {
      cashBalance: currency(0),
      holdings: currency(0),
      totalWorth: currency(0),
    },
    averages: {
      cashBalance: currency(0),
      holdings: currency(0),
      totalWorth: currency(0),
    },
  };

  for (const user of users) {
    const cashBalance = currency(user.cashBalance);
    const holdings = user.positions.reduce(
      (sum, position) => sum.add(currency(position.team.stockPurchasePrices[0]).multiply(position.quantity)),
      currency(0),
    );
    const totalWorth = cashBalance.add(holdings);

    data.players.push({
      id: user.id,
      fullName: user.fullName,
      cashBalance: cashBalance,
      holdings: holdings,
      totalWorth: totalWorth,
      winnings: null,
    });

    data.totals.cashBalance = data.totals.cashBalance.add(cashBalance);
    data.totals.holdings = data.totals.holdings.add(holdings);
    data.totals.totalWorth = data.totals.totalWorth.add(totalWorth);
  }

  data.players.sort((left, right) => {
    if (right.totalWorth.intValue !== left.totalWorth.intValue) {
      return right.totalWorth.intValue - left.totalWorth.intValue;
    }
    return left.fullName.localeCompare(right.fullName);
  });

  data.averages.cashBalance = data.totals.cashBalance.divide(data.players.length);
  data.averages.holdings = data.totals.holdings.divide(data.players.length);
  data.averages.totalWorth = data.totals.totalWorth.divide(data.players.length);

  for (const player of data.players) {
    // payout doesn't use currency to avoid losing precision during calculations
    // make sure to only set currency values on returned data
    const payout = (player.totalWorth.value / data.totals.totalWorth.value) * BUY_IN * data.players.length;
    player.winnings = currency(payout);
  }

  return data;
}
