import React from 'react';
import { Link as MuiLink, Table, TableBody, TableCell, TableHead, TableRow, Theme, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';
import { PlayerStandingsData } from './getPlayerStandingsData';

export default function PlayerStandingsTable({ leagueId, data }: { leagueId: string; data: PlayerStandingsData }) {
  const isMdDown = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));

  return (
    <Table sx={{ maxWidth: 1200 }} size={isMdDown ? 'small' : 'medium'}>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell align="right">Stock Value</TableCell>
          <TableCell align="right">Cash Balance</TableCell>
          <TableCell align="right">Total Value</TableCell>
          <TableCell align="right">Payout</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.players.map(player => (
          <TableRow key={player.id}>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>
              <MuiLink component={Link} to={`/league/${leagueId}/user/${player.id}`}>
                {player.fullName}
              </MuiLink>
            </TableCell>
            <TableCell align="right">{player.holdings.format()}</TableCell>
            <TableCell align="right">{player.cashBalance.format()}</TableCell>
            <TableCell align="right">{player.totalWorth.format()}</TableCell>
            <TableCell align="right">{player.winnings.format()}</TableCell>
          </TableRow>
        ))}
        <TableRow>
          <TableCell>League Total</TableCell>
          <TableCell align="right">{data.totals.holdings.format()}</TableCell>
          <TableCell align="right">{data.totals.cashBalance.format()}</TableCell>
          <TableCell align="right">{data.totals.totalWorth.format()}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>League Average</TableCell>
          <TableCell align="right">{data.averages.holdings.format()}</TableCell>
          <TableCell align="right">{data.averages.cashBalance.format()}</TableCell>
          <TableCell align="right">{data.averages.totalWorth.format()}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}
