import React, { useMemo } from 'react';
import {
  Box,
  CircularProgress,
  Link as MuiLink,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  useMediaQuery,
} from '@mui/material';
import currency from 'currency.js';
import { Link, useParams } from 'react-router-dom';
import { gql, useQuery } from 'urql';
import { formatDateTime } from '../../../../../dates';
import {
  FetchStockMarketUserTransactionHistoryQuery,
  FetchStockMarketUserTransactionHistoryQueryVariables,
} from '../../../../../generated/hooks';
import { BalanceChangeType } from '../../../../../generated/schemas';
import { balanceChangeTypeLabel } from '../../../../../utils';
import { TeamLogoComponent } from '../../../../logos';

const fetchStockMarketUserTransactionHistory = gql`
  query fetchStockMarketUserTransactionHistory($leagueId: UUID!, $userId: UUID!) {
    league(id: $leagueId) {
      user(id: $userId) {
        balanceLogs {
          id
          created
          balanceChangeType
          balance
          data
        }
      }
    }
  }
`;

function PostseasonDividendsLogDescription({
  description,
  leagueId,
  log,
}: {
  description: string;
  leagueId: string;
  log: FetchStockMarketUserTransactionHistoryQuery['league']['user']['balanceLogs'][0];
}) {
  return (
    <Stack direction="row" spacing={0.5} sx={{ alignItems: 'center', whiteSpace: 'nowrap' }}>
      <Box>{currency(log.data['dividends']).format()} for </Box>
      <MuiLink component={Link} to={`/league/${leagueId}/team/${log.data['team']['id']}`}>
        <TeamLogoComponent styles={log.data['team']['styles']} />
      </MuiLink>
      <Box> {description}</Box>
    </Stack>
  );
}

function BalanceLogDescription({
  leagueId,
  log,
}: {
  leagueId: string;
  log: FetchStockMarketUserTransactionHistoryQuery['league']['user']['balanceLogs'][0];
}) {
  if (log.balanceChangeType === BalanceChangeType.InitialBalance) {
    return null;
  }
  if ([BalanceChangeType.SharesPurchased, BalanceChangeType.SharesSold].includes(log.balanceChangeType)) {
    const sharesKey = log.balanceChangeType === BalanceChangeType.SharesPurchased ? 'sharesPurchased' : 'sharesSold';
    const totalKey = log.balanceChangeType === BalanceChangeType.SharesPurchased ? 'totalCost' : 'totalPayment';
    return (
      <Stack direction="row" spacing={0.5} sx={{ alignItems: 'center', whiteSpace: 'nowrap' }}>
        <Box>
          {log.data[sharesKey]} share{log.data[sharesKey] !== 1 && 's'} of{' '}
        </Box>
        <MuiLink component={Link} to={`/league/${leagueId}/team/${log.data['team']['id']}`}>
          <TeamLogoComponent styles={log.data['team']['styles']} />
        </MuiLink>
        <Box> for {currency(log.data[totalKey]).format()}</Box>
      </Stack>
    );
  }
  if (log.balanceChangeType === BalanceChangeType.DividendsReceived) {
    return (
      <Stack direction="row" spacing={0.5} sx={{ alignItems: 'center', whiteSpace: 'nowrap' }}>
        <Box>{currency(log.data['dividends']).format()} for </Box>
        <MuiLink component={Link} to={`/league/${leagueId}/team/${log.data['team']['id']}`}>
          <TeamLogoComponent styles={log.data['team']['styles']} />
        </MuiLink>
        <Box>
          {' '}
          {log.data['score']} - {log.data['opponentScore']}{' '}
        </Box>
        <MuiLink component={Link} to={`/league/${leagueId}/team/${log.data['opponent']['id']}`}>
          <TeamLogoComponent styles={log.data['opponent']['styles']} />
        </MuiLink>
        <Box>
          {' '}
          ({log.data['sharesOwned']} share{log.data['sharesOwned'] !== 1 && 's'})
        </Box>
      </Stack>
    );
  }
  if (log.balanceChangeType === BalanceChangeType.PostseasonDividendsReceived) {
    let description: string;
    if (log.data['type'] === 'Conference Championship') {
      description = `${log.data['conference']} Championship`;
    }
    if (log.data['type'] === 'Bowl Game Winner' || log.data['type'] === 'Playoff Winner') {
      description = `${log.data['bowl']} Winner`;
    }
    if (log.data['type'] === 'Playoff Appearance' || log.data['type'] === 'Playoff First Round Bye') {
      description = log.data['type'];
    }
    const sharesOwned = log.data['sharesOwned'];
    const sharesDescription = `(${sharesOwned} share${sharesOwned !== 1 ? 's' : ''})`;
    description = `${description} ${sharesDescription}`;
    return <PostseasonDividendsLogDescription description={description} leagueId={leagueId} log={log} />;
  }
  return null;
}

export default function TransactionHistory() {
  const { leagueId, userId } = useParams<{ leagueId: string; userId: string }>();
  const [{ data }] = useQuery<
    FetchStockMarketUserTransactionHistoryQuery,
    FetchStockMarketUserTransactionHistoryQueryVariables
  >({
    query: fetchStockMarketUserTransactionHistory,
    variables: { leagueId, userId },
    context: useMemo(() => ({ requestPolicy: 'cache-and-network' }), []),
  });

  const isMdDown = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));

  if (!data) {
    return (
      <Box sx={{ mt: 2, textAlign: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }
  return (
    <Table
      sx={{
        maxWidth: 1200,
        '& .MuiTableCell-sizeSmall': {
          px: 0.5,
        },
        '& .MuiTableCell-sizeSmall:first-of-type': {
          pl: 0,
        },
        '& .MuiTableCell-sizeSmall:last-of-type': {
          pr: 0,
        },
      }}
      size={isMdDown ? 'small' : 'medium'}
    >
      <TableHead>
        <TableRow>
          <TableCell>Time</TableCell>
          <TableCell>Action</TableCell>
          <TableCell align="right">Balance</TableCell>
          <TableCell>Description</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.league.user.balanceLogs.map(log => (
          <TableRow key={log.id}>
            <TableCell>{formatDateTime(log.created)}</TableCell>
            <TableCell>{balanceChangeTypeLabel[log.balanceChangeType]}</TableCell>
            <TableCell align="right">{currency(log.balance).format()}</TableCell>
            <TableCell>
              <BalanceLogDescription leagueId={leagueId} log={log} />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
